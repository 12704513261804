<div class="header">
  <h2 *ngIf="title" class="title">{{ title }}</h2>
  <trp-community-info-icon
    [infoText]="infoText"
    *ngIf="!!infoText"
  ></trp-community-info-icon>
</div>

<div class="wrapper">
  <ng-content></ng-content>
</div>
<div *ngIf="hasAcceptedMaterialsWidget" class="accepted-materials-wrapper">
  <ng-content select="trp-accepted-materials-widget-row"></ng-content>
</div>
<div *ngIf="shouldDisplayFooter" class="section-footer">
  <ng-container
    [ngTemplateOutlet]="footerTemplate"
    [ngTemplateOutletContext]="getFooterTemplateContext()"
  ></ng-container>
</div>
