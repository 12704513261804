import { InjectionToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ContainersPipe,
  CurbsideContaminationPipe,
} from '@trp/community/shared/ui';
import {
  MultiFamilyProgram,
  PrivateCommunityInformation,
  SingleFamilyProgram,
} from '@trp/nationaldb/interfaces';
import { CommunityWidgetInfoUtilService } from '../services';
import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import {
  CommunityProfileWidgetConfig,
  DROPOFF_VERIFIABLE_PROPERTIES,
  GENERAL_INFORMATION_VERIFIABLE_PROPERTIES,
  MULTI_FAMILY_VERIFIABLE_PROPERTIES,
  SINGLE_FAMILY_VERIFIABLE_PROPERTIES,
} from '@trp/community/interfaces';
import {
  hasDropoff,
  hasMultiFamily,
  hasSingleFamily,
} from './community-widget-config.util';

export const profilesWidgetConfigFactory = (
  translateService: TranslateService,
  utilService: CommunityWidgetInfoUtilService,
  containersPipe: ContainersPipe,
  decimalPipe: DecimalPipe,
  currencyPipe: CurrencyPipe,
  percentPipe: PercentPipe,
  curbsideContaminationPipe: CurbsideContaminationPipe
): CommunityProfileWidgetConfig => {
  return {
    generalInformation: {
      section: 'generalInformation',
      title: 'widgets.generalInfo.title',
      maxColumns: 3,
      propertiesToVerify: [...GENERAL_INFORMATION_VERIFIABLE_PROPERTIES],
      widgets: [
        {
          section: 'generalInformation',
          infoType: 'hasProgramDropoff',
          isReadonly: true,
          type: 'standard',
          locations: ['map-profile'],

          title: 'widgets.generalInfo.dropoffCollection',
          icon: 'global:DropOffCollection',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            !!info.hasProgramDropoff,
          availableResolver: () =>
            translateService.instant(
              'widgets.generalInfo.notice.availableForResidents'
            ),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.generalInfo.notice.noDropoffRecyclingCollection'
            ),
        },
        {
          section: 'generalInformation',
          infoType: 'hasProgramSingleFamily',
          isReadonly: true,
          type: 'standard',
          locations: ['map-profile'],

          title: 'widgets.generalInfo.singleFamilyCurbsideCollection',
          icon: 'global:SingleFamilyCurbsideCollection',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            !!info.hasProgramSingleFamily,
          availableResolver: () =>
            translateService.instant(
              'widgets.generalInfo.notice.availableForResidents'
            ),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.generalInfo.notice.noSingleFamilyRecyclingCollection'
            ),
        },
        {
          section: 'generalInformation',
          infoType: 'hasProgramMultiFamily',
          isReadonly: true,
          type: 'standard',
          locations: ['map-profile'],

          title: 'widgets.generalInfo.multifamilyOnPropertyCollection',
          icon: 'custom-solid:Multifamily',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            !!info.hasProgramMultiFamily,
          availableResolver: () =>
            translateService.instant(
              'widgets.generalInfo.notice.availableForResidents'
            ),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.generalInfo.notice.noMultiFamilyRecyclingCollection'
            ),
        },
        {
          section: 'generalInformation',
          infoType: 'onlineSearchUrl',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.generalInfo.onlineSearchUrl',
          icon: 'global:search',
          infoText:
            'requestForm.questions.general.onlineToolForAcceptedMaterialsInfo',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            !!info.onlineSearchUrl,
          availableValueFormat: 'html',
          availableResolver: (info: PrivateCommunityInformation) =>
            `<a href="${info.onlineSearchUrl}" class="link" target="_blank">${info.onlineSearchUrl}</a>`,
          unavailableResolver: () =>
            translateService.instant(
              'widgets.generalInfo.notice.noOnlineSearchUrl'
            ),
        },
        {
          section: 'generalInformation',
          infoType: 'guidelinesWebsite',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.generalInfo.guidelinesWebsite',
          icon: 'global:alternate-email',
          infoText:
            'requestForm.questions.general.webpageForAcceptedMaterialInfo',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            !!info.guidelinesWebsite,
          availableValueFormat: 'html',
          availableResolver: (info: PrivateCommunityInformation) =>
            `<a href="${info.guidelinesWebsite}" class="link" target="_blank">${info.guidelinesWebsite}</a>`,
          unavailableResolver: () =>
            translateService.instant(
              'widgets.generalInfo.notice.noGuidelinesWebsite'
            ),
        },
      ],
    },
    singleFamilyPrograms: {
      section: 'singleFamilyPrograms',
      title: 'widgets.singleFamilyProgram.title',
      maxColumns: 4,
      propertiesToVerify: [...SINGLE_FAMILY_VERIFIABLE_PROPERTIES],
      widgets: [
        {
          section: 'singleFamilyPrograms',
          infoType: 'collectionFrequencySingleFamily',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.singleFamilyProgram.collectionFrequency',
          icon: 'global:SingleFamilyCollectionFrequency',
          infoText:
            'requestForm.questions.singleFamily.collectionFrequencyInfo',
          ifInfoAvailable: (info: SingleFamilyProgram) =>
            !!info.collectionFrequency,
          availableResolver: (info: SingleFamilyProgram) =>
            info.collectionFrequency,
          unavailableResolver: () =>
            translateService.instant(
              'widgets.singleFamilyProgram.notice.noFrequencyInfo'
            ),
        },
        {
          section: 'singleFamilyPrograms',
          infoType: 'containersSingleFamily',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.singleFamilyProgram.containerType',
          icon: 'custom-solid:Container',
          infoText: 'requestForm.questions.singleFamily.containerTypesInfo',
          ifInfoAvailable: (info: SingleFamilyProgram) =>
            (info.containers || []).length > 0,
          availableResolver: (info: SingleFamilyProgram) =>
            containersPipe.transform(
              info.containers,
              translateService.instant(
                'widgets.singleFamilyProgram.notice.noContainerInfo'
              )
            ),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.singleFamilyProgram.notice.noContainerInfo'
            ),
        },
        {
          section: 'singleFamilyPrograms',
          infoType: 'collectionTypeSingleFamily',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.singleFamilyProgram.typeOfCollection',
          icon: 'custom-solid:CollectionFormat',
          infoText:
            'requestForm.questions.singleFamily.recyclingCollectionTypeInfo',
          ifInfoAvailable: (info: SingleFamilyProgram) => !!info.collectionType,
          availableResolver: (info: SingleFamilyProgram) => info.collectionType,
          unavailableResolver: () =>
            translateService.instant(
              'widgets.singleFamilyProgram.notice.noCollectionTypeInfo'
            ),
        },
        {
          section: 'singleFamilyPrograms',
          infoType: 'householdsServedSingleFamily',
          isReadonly: true,
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.singleFamilyProgram.householdsServed',
          icon: 'global:HouseholdsServed',
          infoText: 'requestForm.questions.singleFamily.householdsServedInfo',
          ifInfoAvailable: (info: SingleFamilyProgram) =>
            (info.householdsServed || 0) > 0,
          availableResolver: (info: SingleFamilyProgram) =>
            decimalPipe.transform(info.householdsServed, '1.0-0'),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.singleFamilyProgram.notice.noHouseholdInfo'
            ),
        },
      ],
    },
    multiFamilyPrograms: {
      section: 'multiFamilyPrograms',
      title: 'widgets.multiFamilyProgram.title',
      maxColumns: 3,
      propertiesToVerify: [...MULTI_FAMILY_VERIFIABLE_PROPERTIES],
      widgets: [
        {
          section: 'multiFamilyPrograms',
          infoType: 'collectionTypeMultiFamily',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.multiFamilyProgram.typeOfCollection',
          icon: 'custom-solid:CollectionFormat',
          infoText: 'requestForm.questions.multiFamily.collectionTypeInfo',
          ifInfoAvailable: (info: MultiFamilyProgram) => !!info.collectionType,
          availableResolver: (info: MultiFamilyProgram) => info.collectionType,
          unavailableResolver: () =>
            translateService.instant(
              'widgets.multiFamilyProgram.notice.noCollectionTypeInfo'
            ),
        },
        {
          section: 'multiFamilyPrograms',
          infoType: 'hasAdditionalServices',
          type: 'standard',
          locations: ['community-profile'],

          title: 'widgets.multiFamilyProgram.additionalMultifamilyServices',
          icon: 'global:AdditionalMultifamilyServices',
          infoText: 'requestForm.questions.multiFamily.additionalServicesInfo',
          ifInfoAvailable: (info: MultiFamilyProgram) =>
            utilService.doesMultiFamilyHaveAdditionalSevices(info),
          availableResolver: () =>
            translateService.instant(
              'widgets.multiFamilyProgram.notice.yesAdditionalServices'
            ),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.multiFamilyProgram.notice.noAdditionalServices'
            ),
        },
        {
          section: 'multiFamilyPrograms',
          infoType: 'householdsServedMultiFamily',
          isReadonly: true,
          type: 'standard',
          locations: ['community-profile'],
          title: 'widgets.multiFamilyProgram.householdsServed',
          icon: 'global:HouseholdsServed',
          infoText: 'requestForm.questions.multiFamily.householdsServedInfo',
          ifInfoAvailable: (info: MultiFamilyProgram) =>
            (info.householdsServed || 0) > 0,
          availableResolver: (info: MultiFamilyProgram) =>
            decimalPipe.transform(info.householdsServed, '1.0-0'),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.multiFamilyProgram.notice.noHouseholdInfo'
            ),
        },
      ],
    },
    dropoffPrograms: {
      section: 'dropoffPrograms',
      title: 'widgets.dropoffProgram.title',
      maxColumns: 3,
      propertiesToVerify: [...DROPOFF_VERIFIABLE_PROPERTIES],
      widgets: [],
    },
    programOutputs: {
      section: 'programOutputs',
      title: 'widgets.programOutput.title',
      maxColumns: 4,
      propertiesToVerify: [],
      widgets: [
        {
          section: 'programOutputs',
          infoType: 'recyclingTonsTotal',
          infoText: 'requestForm.questions.programOutputs.totalTonnageInfo',
          isReadonly: true,
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.programOutput.recyclingTonsTotal',
          icon: 'custom-solid:TonsRecycled',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            (info.totalTonnage || 0) > 0,
          availableResolver: (info: PrivateCommunityInformation) =>
            decimalPipe.transform(info.totalTonnage, '1.0-0'),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.programOutput.notice.noTotalTonnageInfo'
            ),
        },
        {
          section: 'programOutputs',
          infoType: 'recyclingTonsSingleFamily',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.programOutput.recyclingTonsSingleFamily',
          icon: 'custom-solid:TonsRecycled',
          infoText:
            'requestForm.questions.programOutputs.singleFamilyTonnageInfo',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            hasSingleFamily(info) && (info.singleFamilyTonnage || 0) > 0,
          availableResolver: (info: PrivateCommunityInformation) =>
            decimalPipe.transform(info.singleFamilyTonnage, '1.0-0'),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.programOutput.notice.noSingleFamilyTonnageInfo'
            ),
        },
        {
          section: 'programOutputs',
          infoType: 'recyclingTonsMultiFamily',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.programOutput.recyclingTonsMultiFamily',
          icon: 'custom-solid:TonsRecycled',
          infoText:
            'requestForm.questions.programOutputs.multiFamilyTonnageInfo',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            hasMultiFamily(info) && (info.multiFamilyTonnage || 0) > 0,
          availableResolver: (info: PrivateCommunityInformation) =>
            decimalPipe.transform(info.multiFamilyTonnage, '1.0-0'),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.programOutput.notice.noMultiFamilyTonnageInfo'
            ),
        },
        {
          section: 'programOutputs',
          infoType: 'recyclingTonsDropoff',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.programOutput.recyclingTonsDropoff',
          icon: 'custom-solid:TonsRecycled',
          infoText: 'requestForm.questions.programOutputs.dropoffTonnageInfo',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            hasDropoff(info) && (info.dropoffTonnage || 0) > 0,
          availableResolver: (info: PrivateCommunityInformation) =>
            decimalPipe.transform(info.dropoffTonnage, '1.0-0'),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.programOutput.notice.noTonnageDropoffInfo'
            ),
        },
        {
          section: 'programOutputs',
          infoType: 'poundsPerHousehold',
          infoText:
            'requestForm.questions.programOutputs.poundsPerHouseholdInfo',
          isReadonly: true,
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.programOutput.poundsPerHousehold',
          icon: 'custom-solid:CaptureRate',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            (info.poundsPerHousehold || 0) > 0,
          availableResolver: (info: PrivateCommunityInformation) =>
            decimalPipe.transform(info.poundsPerHousehold, '1.0-0'),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.programOutput.notice.noPoundsPerHouseholdInfo'
            ),
        },
        {
          section: 'programOutputs',
          infoType: 'contaminationRatePercentage',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.programOutput.contaminationRate',
          icon: 'custom-solid:ContaminationRate',
          infoText:
            'requestForm.questions.programOutputs.contaminationRatePercentageInfo',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            hasSingleFamily(info) &&
            (info.contaminationRatePercentage || 0) > 0,
          availableResolver: (info: PrivateCommunityInformation) =>
            info.contaminationRatePercentage,
          unavailableResolver: () =>
            translateService.instant(
              'widgets.programOutput.notice.noSingleFamilyContaminationInfo'
            ),
        },
        {
          section: 'programOutputs',
          infoType: 'setOutRatePercentage',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.programOutput.setOutRateSingleFamily',
          icon: 'custom-solid:RecyclingParticipation',
          infoText:
            'requestForm.questions.programOutputs.setOutRatePercentageInfo',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            hasSingleFamily(info) && (info.setOutRatePercentage || 0) > 0,
          availableResolver: (info: PrivateCommunityInformation) =>
            info.setOutRatePercentage,
          unavailableResolver: () =>
            translateService.instant(
              'widgets.programOutput.notice.noSingleFamilySetOutRateInfo'
            ),
        },
        {
          section: 'programOutputs',
          infoType: 'metricTonsCO2AvoidedPerYear',
          infoText: 'widgets.programOutput.co2SavingsInfo',
          isReadonly: true,
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.programOutput.co2Savings',
          icon: 'custom-solid:CO2',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            ((info.totalTonnage || 0) > 0 ||
              (info.singleFamilyTonnage || 0) > 0) &&
            (info.metricTonsCO2AvoidedPerYear || 0) > 0,
          availableResolver: (info: PrivateCommunityInformation) =>
            decimalPipe.transform(info.metricTonsCO2AvoidedPerYear, '1.0-0'),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.programOutput.notice.noTonsRecyclablesInfo'
            ),
        },
      ],
    },
    programResources: {
      section: 'programResources',
      title: 'widgets.programResources.title',
      maxColumns: 2,
      propertiesToVerify: [],
      widgets: [
        {
          section: 'programResources',
          infoType: 'curbsideContamination',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.programResources.curbsideContamination',
          icon: 'custom-solid:ContaminationRate',
          infoText:
            'requestForm.questions.programResources.curbsideContaminationControlInfo',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            hasSingleFamily(info) &&
            (info.contaminationControls || []).length !== 0,
          availableValueFormat: 'html',
          availableResolver: (info: PrivateCommunityInformation) => {
            return curbsideContaminationPipe.transform(
              info.contaminationControls
            );
          },
          unavailableResolver: (info: PrivateCommunityInformation) => {
            if (!hasSingleFamily(info)) {
              return translateService.instant(
                'widgets.programResources.notice.singleFamilyCurbsideCollectionUnavailable'
              );
            }

            if (
              hasSingleFamily(info) &&
              (info.contaminationControls || []).length === 0
            ) {
              return translateService.instant(
                'widgets.programResources.notice.noContaminationControlInfo'
              );
            }

            return '';
          },
        },
        {
          section: 'programResources',
          infoType: 'outreachBudgetPerHouseHold',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.programResources.annualOutreach',
          icon: 'custom-solid:MedianIncom',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            (info.outreachBudgetPerHouseHold || 0) > 0,
          availableResolver: (info: PrivateCommunityInformation) =>
            info.outreachBudgetPerHouseHold,
          unavailableResolver: () =>
            translateService.instant(
              'widgets.programResources.notice.noAnnualOutreachBudgetInfo'
            ),
        },
        {
          section: 'programResources',
          infoType: 'numberOfFullTimeStaff',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.programResources.numberOfFullTimeStaff',
          icon: 'custom-solid:NumberOfFullTimeEquivalentStaff',
          infoText:
            'requestForm.questions.programResources.nFulltimeEquivalentStaffInfo',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            (info.numberOfFullTimeStaff || 0) > 0,
          availableResolver: (info: PrivateCommunityInformation) =>
            info.numberOfFullTimeStaff,
          unavailableResolver: () =>
            translateService.instant(
              'widgets.programResources.notice.noNumberOfFullTimeStaffInfo'
            ),
        },
      ],
    },

    censusData: {
      section: 'censusData',
      title: 'widgets.censusData.title',
      maxColumns: 3,
      propertiesToVerify: [],
      widgets: [
        {
          section: 'censusData',
          infoType: 'totalPopulation',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.censusData.population',
          icon: 'custom-solid:Population',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            (info.totalPopulation || 0) > 0,
          availableResolver: (info: PrivateCommunityInformation) =>
            decimalPipe.transform(info.totalPopulation, '1.0-0'),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.censusData.notice.noPopulationInfo'
            ),
        },
        {
          section: 'censusData',
          infoType: 'occupancyRate',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.censusData.occupancy',
          icon: 'global:OccupancyRate',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            (info.occupancyRate ?? 0) > 0,
          availableResolver: (info: PrivateCommunityInformation) =>
            percentPipe.transform((info.occupancyRate ?? 0) / 100),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.censusData.notice.noOccupancyRateInfo'
            ),
        },
        {
          section: 'censusData',
          infoType: 'percentRaceBipoc',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.censusData.bipoc',
          icon: 'custom-solid:BIPOC',
          ifInfoAvailable: (info: PrivateCommunityInformation) => {
            return (
              typeof info.percentRaceBipoc === 'number' &&
              info.percentRaceBipoc > -1
            );
          },
          availableResolver: (info: PrivateCommunityInformation) =>
            percentPipe.transform((info.percentRaceBipoc ?? 0) / 100),
          unavailableResolver: () =>
            translateService.instant('widgets.censusData.notice.noBipocInfo'),
        },
        {
          section: 'censusData',
          infoType: 'percentBachelorsOrHigher',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.censusData.bscOrHigher',
          icon: 'custom-solid:BachelorsDegree',
          ifInfoAvailable: (info: PrivateCommunityInformation) => {
            return (
              typeof info.percentBachelorsOrHigher === 'number' &&
              info.percentBachelorsOrHigher > -1
            );
          },
          availableResolver: (info: PrivateCommunityInformation) =>
            percentPipe.transform((info.percentBachelorsOrHigher ?? 0) / 100),
          unavailableResolver: () =>
            translateService.instant(
              'widgets.censusData.notice.noEducationInfo'
            ),
        },
        {
          section: 'censusData',
          infoType: 'incomeMean',
          type: 'standard',
          locations: ['community-profile', 'map-profile'],

          title: 'widgets.censusData.meanIncome',
          icon: 'custom-solid:MedianIncom',
          ifInfoAvailable: (info: PrivateCommunityInformation) =>
            (info.incomeMean || 0) > 0,
          availableResolver: (info: PrivateCommunityInformation) =>
            currencyPipe.transform(info.incomeMean, '$', 'symbol', '1.0-0'),
          unavailableResolver: () =>
            translateService.instant('widgets.censusData.notice.noIncomeInfo'),
        },
      ],
    },
  };
};

export const PROFILES_WIDGET_CONFIG_TOKEN =
  new InjectionToken<CommunityProfileWidgetConfig>(
    'PROFILES_WIDGET_CONFIG_TOKEN'
  );
